* {
	image-rendering: pixelated;
	image-rendering: crisp-edges;
	box-sizing: border-box;
	background: transparent;
	margin: 0;
	border: 0;
	padding: 0;
}
html, body {
    overflow: hidden;
    height: 100vh;
}
body {
	margin: 0;
	/*padding: 20vh 0;*/
	/*padding: 50px;*/
	font-family: sans-serif;
	/*background-image: url(./imgs/fundo_appart732.gif);*/
	/*background-image: url(./imgs/Fundo-trax-pattern.gif);*/
	/*background-position: center;*/
	/*background-size: cover;*/
}
input[type="button"] {
	outline: none;
}
input:disabled {
	cursor: not-allowed;
}
input:not(:disabled) {
	cursor: pointer;
}
#social {
	position: absolute;
	z-index: 0;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
}