.MusicasSalvas {
	width: 181px;

}
.MusicasSalvas h2 {
	color: #eee;
	text-align: center;
	margin-bottom: 7px;
}
.MusicasSalvas .lista {
	width: 181px;
	height: 189px;
	margin-bottom: 7px;
	padding: 5px;
	background-image: url(./../../imgs/saved-songs-list-background.png);
}
.MusicasSalvas .lista select {
	width: 100%;
	height: 100%;
	outline: none;
}
.MusicasSalvas .lista select option {
	color: #eee;
	font-weight: normal;
	padding: 2px 1px;
	margin: 2px 1px;
}
.MusicasSalvas .lista select option:not(:disabled) {
	background-color: #dbe1dc;
	border-radius: 20px;
	color: gray;
	padding-left: 20px
}
/*.MusicasSalvas .lista select option:selected {
	background-color: black;
}*/
.MusicasSalvas .editar {
	color: #eee;
	width: 132px;
	height: 23px;
	background-image: url(./../../imgs/editar-song.png);
	float: left;
}
.MusicasSalvas .excluir {
	width: 38px;
	height: 23px;
	background-image: url(./../../imgs/delete-song.png);
	float: right;
}