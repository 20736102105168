.modulo {
	display: inline-block;
	width: 21px;
	height: 21px;
	overflow: hidden;
}
.modulo:not([data-moduloClasse]), .modulo:not([data-moduloCor]) {
	background: url(./imgs/abc.png);
}
.modulo[data-moduloClasse], .modulo[data-moduloCor] {
	background-size: 13px 21px, 21px 13px, 17px 17px;
	background-position: 4px 0px, 0px 4px, 2px 2px;
	background-repeat: no-repeat;
}
.modulo[data-moduloClasse="1"][data-moduloCor='1'] + .modulo[data-moduloClasse="1"][data-moduloCor='1']:before,
.modulo[data-moduloClasse="1"][data-moduloCor='2'] + .modulo[data-moduloClasse="1"][data-moduloCor='2']:before,
.modulo[data-moduloClasse="1"][data-moduloCor='3'] + .modulo[data-moduloClasse="1"][data-moduloCor='3']:before,
.modulo[data-moduloClasse="1"][data-moduloCor='4'] + .modulo[data-moduloClasse="1"][data-moduloCor='4']:before,
.modulo[data-moduloClasse="2"][data-moduloCor='1'] + .modulo[data-moduloClasse="2"][data-moduloCor='1']:before,
.modulo[data-moduloClasse="2"][data-moduloCor='2'] + .modulo[data-moduloClasse="2"][data-moduloCor='2']:before,
.modulo[data-moduloClasse="2"][data-moduloCor='3'] + .modulo[data-moduloClasse="2"][data-moduloCor='3']:before,
.modulo[data-moduloClasse="2"][data-moduloCor='4'] + .modulo[data-moduloClasse="2"][data-moduloCor='4']:before,
.modulo[data-moduloClasse="3"][data-moduloCor='1'] + .modulo[data-moduloClasse="3"][data-moduloCor='1']:before,
.modulo[data-moduloClasse="3"][data-moduloCor='2'] + .modulo[data-moduloClasse="3"][data-moduloCor='2']:before,
.modulo[data-moduloClasse="3"][data-moduloCor='3'] + .modulo[data-moduloClasse="3"][data-moduloCor='3']:before,
.modulo[data-moduloClasse="3"][data-moduloCor='4'] + .modulo[data-moduloClasse="3"][data-moduloCor='4']:before,
.modulo[data-moduloClasse="4"][data-moduloCor='1'] + .modulo[data-moduloClasse="4"][data-moduloCor='1']:before,
.modulo[data-moduloClasse="4"][data-moduloCor='2'] + .modulo[data-moduloClasse="4"][data-moduloCor='2']:before,
.modulo[data-moduloClasse="4"][data-moduloCor='3'] + .modulo[data-moduloClasse="4"][data-moduloCor='3']:before,
.modulo[data-moduloClasse="4"][data-moduloCor='4'] + .modulo[data-moduloClasse="4"][data-moduloCor='4']:before,
.modulo[data-moduloClasse="5"][data-moduloCor='1'] + .modulo[data-moduloClasse="5"][data-moduloCor='1']:before,
.modulo[data-moduloClasse="5"][data-moduloCor='2'] + .modulo[data-moduloClasse="5"][data-moduloCor='2']:before,
.modulo[data-moduloClasse="5"][data-moduloCor='3'] + .modulo[data-moduloClasse="5"][data-moduloCor='3']:before,
.modulo[data-moduloClasse="5"][data-moduloCor='4'] + .modulo[data-moduloClasse="5"][data-moduloCor='4']:before,
.modulo[data-moduloClasse="6"][data-moduloCor='1'] + .modulo[data-moduloClasse="6"][data-moduloCor='1']:before,
.modulo[data-moduloClasse="6"][data-moduloCor='2'] + .modulo[data-moduloClasse="6"][data-moduloCor='2']:before,
.modulo[data-moduloClasse="6"][data-moduloCor='3'] + .modulo[data-moduloClasse="6"][data-moduloCor='3']:before,
.modulo[data-moduloClasse="6"][data-moduloCor='4'] + .modulo[data-moduloClasse="6"][data-moduloCor='4']:before,
.modulo[data-moduloClasse="7"][data-moduloCor='1'] + .modulo[data-moduloClasse="7"][data-moduloCor='1']:before,
.modulo[data-moduloClasse="7"][data-moduloCor='2'] + .modulo[data-moduloClasse="7"][data-moduloCor='2']:before,
.modulo[data-moduloClasse="7"][data-moduloCor='3'] + .modulo[data-moduloClasse="7"][data-moduloCor='3']:before,
.modulo[data-moduloClasse="7"][data-moduloCor='4'] + .modulo[data-moduloClasse="7"][data-moduloCor='4']:before,
.modulo[data-moduloClasse="8"][data-moduloCor='1'] + .modulo[data-moduloClasse="8"][data-moduloCor='1']:before,
.modulo[data-moduloClasse="8"][data-moduloCor='2'] + .modulo[data-moduloClasse="8"][data-moduloCor='2']:before,
.modulo[data-moduloClasse="8"][data-moduloCor='3'] + .modulo[data-moduloClasse="8"][data-moduloCor='3']:before,
.modulo[data-moduloClasse="8"][data-moduloCor='4'] + .modulo[data-moduloClasse="8"][data-moduloCor='4']:before,
.modulo[data-moduloClasse="9"][data-moduloCor='1'] + .modulo[data-moduloClasse="9"][data-moduloCor='1']:before,
.modulo[data-moduloClasse="9"][data-moduloCor='2'] + .modulo[data-moduloClasse="9"][data-moduloCor='2']:before,
.modulo[data-moduloClasse="9"][data-moduloCor='3'] + .modulo[data-moduloClasse="9"][data-moduloCor='3']:before,
.modulo[data-moduloClasse="9"][data-moduloCor='4'] + .modulo[data-moduloClasse="9"][data-moduloCor='4']:before,
.modulo[trax-moduleType="middle"]:before,
.modulo[trax-moduleType="end"]:before {
	content: "";
    width: 3px;
    height: 13px;
    position: absolute;
    z-index: 1;
    background-image: url(./imgs/union.png);
    transform: translate(-2px, 4px);
}

.modulo:after {
	content: "";
	width: 21px;
	height: 21px;
	position: absolute;
}
.modulo[data-moduloClasse="1"]:after {
	background: url(./imgs/00008.png) 1px 1px, url(./imgs/module.png);
}
.modulo[data-moduloClasse="2"]:after {
	background: url(./imgs/00008.png) -18px 1px, url(./imgs/module.png);
}
.modulo[data-moduloClasse="3"]:after {
	background: url(./imgs/00008.png) -37px 1px, url(./imgs/module.png);
}
.modulo[data-moduloClasse="4"]:after {
	background: url(./imgs/00008.png) -56px 1px, url(./imgs/module.png);
}
.modulo[data-moduloClasse="5"]:after {
	background: url(./imgs/00008.png) -75px 1px, url(./imgs/module.png);
}
.modulo[data-moduloClasse="6"]:after {
	background: url(./imgs/00008.png) -93px 1px, url(./imgs/module.png);
}
.modulo[data-moduloClasse="7"]:after {
	background: url(./imgs/00008.png) -113px 1px, url(./imgs/module.png);
}
.modulo[data-moduloClasse="8"]:after {
	background: url(./imgs/00008.png) -132px 1px, url(./imgs/module.png);
}
.modulo[data-moduloClasse="9"]:after {
	background: url(./imgs/00008.png) -151px 1px, url(./imgs/module.png);
}

li .modulo[data-moduloClasse="1"]:hover:after {
	background: url(./imgs/00008.png) 1px 1px, url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="2"]:hover:after {
	background: url(./imgs/00008.png) -18px 1px, url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="3"]:hover:after {
	background: url(./imgs/00008.png) -37px 1px, url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="4"]:hover:after {
	background: url(./imgs/00008.png) -56px 1px, url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="5"]:hover:after {
	background: url(./imgs/00008.png) -75px 1px, url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="6"]:hover:after {
	background: url(./imgs/00008.png) -93px 1px, url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="7"]:hover:after {
	background: url(./imgs/00008.png) -113px 1px, url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="8"]:hover:after {
	background: url(./imgs/00008.png) -132px 1px, url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="9"]:hover:after {
	background: url(./imgs/00008.png) -151px 1px, url(./imgs/module-hover.png);
}

li .modulo[data-moduloClasse="1"][data-ativado = "true"]:hover:after {
	background: url(./imgs/00008.png) 1px 1px, url(./imgs/module-down.png), url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="2"][data-ativado = "true"]:hover:after {
	background: url(./imgs/00008.png) -18px 1px, url(./imgs/module-down.png), url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="3"][data-ativado = "true"]:hover:after {
	background: url(./imgs/00008.png) -37px 1px, url(./imgs/module-down.png), url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="4"][data-ativado = "true"]:hover:after {
	background: url(./imgs/00008.png) -56px 1px, url(./imgs/module-down.png), url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="5"][data-ativado = "true"]:hover:after {
	background: url(./imgs/00008.png) -75px 1px, url(./imgs/module-down.png), url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="6"][data-ativado = "true"]:hover:after {
	background: url(./imgs/00008.png) -93px 1px, url(./imgs/module-down.png), url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="7"][data-ativado = "true"]:hover:after {
	background: url(./imgs/00008.png) -113px 1px, url(./imgs/module-down.png), url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="8"][data-ativado = "true"]:hover:after {
	background: url(./imgs/00008.png) -132px 1px, url(./imgs/module-down.png), url(./imgs/module-hover.png);
}
li .modulo[data-moduloClasse="9"][data-ativado = "true"]:hover:after {
	background: url(./imgs/00008.png) -151px 1px, url(./imgs/module-down.png), url(./imgs/module-hover.png);
}

.modulo[data-moduloClasse="1"][data-ativado = "true"]:after {
	background: url(./imgs/00008.png) 2px 2px, url(./imgs/module-down.png);
}
.modulo[data-moduloClasse="2"][data-ativado = "true"]:after {
	background: url(./imgs/00008.png) -17px 2px, url(./imgs/module-down.png);
}
.modulo[data-moduloClasse="3"][data-ativado = "true"]:after {
	background: url(./imgs/00008.png) -36px 2px, url(./imgs/module-down.png);
}
.modulo[data-moduloClasse="4"][data-ativado = "true"]:after {
	background: url(./imgs/00008.png) -55px 2px, url(./imgs/module-down.png);
}
.modulo[data-moduloClasse="5"][data-ativado = "true"]:after {
	background: url(./imgs/00008.png) -74px 2px, url(./imgs/module-down.png);
}
.modulo[data-moduloClasse="6"][data-ativado = "true"]:after {
	background: url(./imgs/00008.png) -92px 2px, url(./imgs/module-down.png);
}
.modulo[data-moduloClasse="7"][data-ativado = "true"]:after {
	background: url(./imgs/00008.png) -112px 2px, url(./imgs/module-down.png);
}
.modulo[data-moduloClasse="8"][data-ativado = "true"]:after {
	background: url(./imgs/00008.png) -131px 2px, url(./imgs/module-down.png);
}
.modulo[data-moduloClasse="9"][data-ativado = "true"]:after {
	background: url(./imgs/00008.png) -150px 2px, url(./imgs/module-down.png);
}

/*-------------------------*/

*[data-moduloCor='1'] {
	color: #436C00;
}
.modulo[data-moduloCor='1'] + .modulo[data-moduloCor='1']:before,
*[data-moduloCor='1'][data-moduloTipo='middle']:before,
*[data-moduloCor='1'][data-moduloTipo='end']:before {
	background-color: #89dc00;
}
.modulo[data-moduloCor='1'] {
	background-image:
		linear-gradient(#89dc00, #89dc00),
		linear-gradient(#89dc00, #89dc00),
		linear-gradient(#89dc00, #89dc00);
}

*[data-moduloCor='2'] {
	color: #824E00;
}
.modulo[data-moduloCor='2'] + .modulo[data-moduloCor='2']:before,
*[data-moduloCor='2'][data-moduloTipo='middle']:before,
*[data-moduloCor='2'][data-moduloTipo='end']:before {
	background-color: #efb100;
}
.modulo[data-moduloCor='2'] {
	background-image:
		linear-gradient(#efb100, #efb100),
		linear-gradient(#efb100, #efb100),
		linear-gradient(#efb100, #efb100);
}
*[data-moduloCor='3'] {
	color: #800062;
}
.modulo[data-moduloCor='3'] + .modulo[data-moduloCor='3']:before,
*[data-moduloCor='3'][data-moduloTipo='middle']:before,
*[data-moduloCor='3'][data-moduloTipo='end']:before {
	background-color: #ef00b8;
}
.modulo[data-moduloCor='3'] {
	background-image:
		linear-gradient(#ef00b8, #ef00b8),
		linear-gradient(#ef00b8, #ef00b8),
		linear-gradient(#ef00b8, #ef00b8);
}
*[data-moduloCor='4'] {
	color: #304B56;
}
.modulo[data-moduloCor='4'] + .modulo[data-moduloCor='4']:before,
*[data-moduloCor='4'][data-moduloTipo='middle']:before,
*[data-moduloCor='4'][data-moduloTipo='end']:before {
	background-color: #00d2dc;
}
.modulo[data-moduloCor='4'] {
	background-image:
		linear-gradient(#00d2dc, #00d2dc),
		linear-gradient(#00d2dc, #00d2dc),
		linear-gradient(#00d2dc, #00d2dc);
}