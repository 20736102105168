.Caixa {
	width: max-content;
	/* background-image: url(./imgs/caixa-azul-fundo_fundo.png); */
	/* background-clip: padding-box; */
	border: 15px solid transparent;
	border-top-width: 9px;
	border-left-width: 12px;
	border-image-source: url(./imgs/caixa-azul-fundo_borda.png);
	border-image-repeat: repeat;
	border-image-width: auto;
	border-image-slice: 15 fill;
}
.Caixa > * {
	text-align: center;
}
.Caixa > header {
	position: relative;
	font-weight: bold;
	color: #fff;
	margin: -4px -9px 8px -8px;
	padding-top: 5px;
	height: 16px;
}
.Caixa > header > button {
	display: inline-block;
	font-size: 0;
	width: 12px;
	height: 12px;
	background-image: url(./imgs/button-close-light.png);
	cursor: pointer;
	position: absolute;
	right: 9px;
	top: 1px;
}
.Caixa > header > button ~ h1:before,
.Caixa > header > button ~ h1:after {
	background-image: url(./imgs/header_bg_dots.png);
}
.Caixa > header > h1 {
	display: flex;
}
.Caixa > header > h1:before,
.Caixa > header > h1:after {
	content: 'dots';
	color: transparent;
	display: inline-block;
	height: 16px;
	flex-grow: 1;
	margin-top: -5px;
}
.Caixa > header > h1:before {
	border-top-left-radius: 10px;
	margin-right: 6px;
}
.Caixa > header > h1:after {
	border-top-right-radius: 10px;
	margin-left: 6px;
}
.Caixa > main {
	padding: 10px 9px 10px 8px;
	/* background-image: url(./imgs/caixa-azul-corpo_fundo.png);
	background-clip: padding-box; */
	border: 3px solid transparent;
	border-image-source: url(./imgs/caixa-azul-corpo_borda.png);
	border-image-repeat: repeat;
	border-image-width: auto;
	border-image-slice: 6 fill;
}
.Caixa > footer {
	padding-top: 4px;
	padding-bottom: 2px;
}
.Caixa > footer > input:first-child {
	float: left;
}
.Caixa > footer > input:last-child {
	float: right;
}