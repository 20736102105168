.Cadastro {
	position: absolute;
	top: 56px;
	right: -3px;
	width: 307px;
}
.Cadastro > main {
	text-align: left;
	padding-top: 20px;
	padding-right: 13px;
	padding-left: 13px;
}
.Cadastro > main > form label > span {
	display: inline-block;
	margin-left: 5px;
	margin-bottom: 2px;
}
.Cadastro > main > form label {
	display: inline-block;
	margin-bottom: 11px;
}
.Cadastro > main > form > label > input[type=text] {
	width: 97px;
}
.Cadastro > main > form > label > input[type=e-mail] {
	width: 248px;
}
.Cadastro > main > form label > input[type=password] {
	width: 117px;
}
.Cadastro > main > form > div {
	display: flex;
}
.Cadastro > main > form > div > div {
	flex: 50%;
}
.Cadastro > main > form > div > div:nth-child(2) > label {
	float: right;
}
.Cadastro > main > form > label:nth-child(5) {
	margin-top: 10px;
}
.Cadastro > main > form > label > input[type=checkbox] {
	margin-right: 10px;
}
.Cadastro > main > button {
	text-decoration: underline;
	color: #9a0202;
	width: 100%;
	font-weight: normal;
	margin-bottom: 10px;
}
.Cadastro > main > form > fieldset > select {
	margin-bottom: 10px;
}
.Cadastro > main > form > fieldset > span:nth-child(6) {
	width: fit-content;
	display: block;
	margin: 0 auto;
}
.Cadastro > main > form > fieldset > label > img {
    vertical-align: middle;
    margin-left: 5px;
}
.Cadastro > main > form > fieldset > button {
	text-decoration: underline;
}
.Cadastro > footer > span {
	color: white;
	font-weight: bold;
	line-height: 16px;
}