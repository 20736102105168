.MudarLista {
	position: relative;
	width: 455px;
	height: 320px;
	margin: 0 auto;
	padding: 5px 6px 5px 4px;
	background-image: url(./../../imgs/lista-window.png);
}
.MudarLista .cabecalho {
	position: relative;
	text-align: center;
	border-radius: 10px 10px 0 0;
	background-image: url(./../../imgs/header-background-dark.png);
	height: 15px;
}
.MudarLista .cabecalho h1 {
	display: inline-block;
	color: #eee;
	font-size: 9px;
	padding: 0 6px;
	background-color: #477681;
    vertical-align: top;
    padding-top: 5px;
}
.MudarLista .cabecalho .fechar {
	position: absolute;
	top: 1px;
	right: 7px;
	width: 13px;
	height: 13px;
	border: 1px solid #477681;
	background-image: url(./../../imgs/button-close-dark.png);
	background-position: center;
	background-repeat: no-repeat;
	background-color: #477681;
}
.MudarLista .corpo {
	position: relative;
	width: 100%;
	height: calc(100% - 15px);
	/*display: flex;*/
	/*align-items: flex-start;*/
	border-width: 13px 10px 12px 14px;
	border-style: solid;
	border-color: transparent;
}
.MudarLista .corpo > * {
	position: absolute;
}
.MudarLista .MusicasSalvas {
	top: 0;
	left: 0;
}
.MudarLista .incluir {
	z-index: 1;
	top: 55px;
	left: 184px;
	width: 27px;
	height: 23px;
	background-image: url(./../../imgs/include-song.png);
}
.MudarLista .divisor {
	z-index: 0;
	width: 1px;
	height: 2px;
	background-image: url(./../../imgs/divider.png);
}
.MudarLista .ListaDeReproducao {
	right: 0;
	top: 0;
}
.MudarLista .InfoMusica {
	right: 0;
	bottom: 0;
}
.MudarLista .criar {
	color: #eee;
	left: 0;
	bottom: 0;
	width: 181px;
	height: 23px;
	border: 5px transparent solid;
	border-image: url(./../../imgs/button.png) 5 repeat;
	background-color: #5e9195;
	background-clip: padding-box;
}