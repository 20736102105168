.ListaSonsPager {
	background-image: url(./../../imgs/pager-background.png);
	width: 74px;
	height: 24px;
	margin: 0 auto;
	padding: 2px 8px 6px 8px;
}
.ListaSonsPager input:first-of-type {
	background-image: url(./../../imgs/pager-left.png);
    width: 10px;
    height: 16px;
}
.ListaSonsPager input:last-of-type {
	background-image: url(./../../imgs/pager-right.png);
	width: 10px;
	height: 16px;
}
.ListaSonsPager input:disabled {
	background-position: -10px 0;
}
.ListaSonsPager span {
	text-align: center;
	font-size: 7pt;
	line-height: 16px;
	vertical-align: top;
	color: rgba(255,255,255,.5);
}
.ListaSonsPager > span {
	display: inline-block;
	width: 38px;
}