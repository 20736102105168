.Controles {
	display: block;
	width: 555px;
	height: 23px;
	margin-bottom: -1px;
	margin-left: auto;
	margin-right: auto;
	padding-right: 20px;
	text-align: right;
}

.Controles .reproduzirPausar {
	background-image: url(./../../imgs/play.png);
}
.Controles .parar {
	background-image: url(./../../imgs/stop.png);
}
.Controles .salvar {
	display: none;
	background-image: url(./../../imgs/save.png);
}
.Controles .abrir {
	display: none;
	background-image: url(./../../imgs/open.png);
}
.Controles .reproduzirPausar, .Controles .parar, .Controles .salvar, .Controles .abrir {
	width: 56px;
    height: 23px;
    margin-right: 5px;
}
.Controles .reproduzirPausar:disabled, .Controles .parar:disabled, .Controles .salvar:disabled, .Controles .abrir:disabled {
	background-position: -112px 0;
}
.Controles .limpar {
	background-image: url(./../../imgs/clear.png);
	width: 36px;
	height: 23px;
	margin-right: 5px;
}
.Controles .limpar:disabled {
	background-position: -72px 0;
}
.Controles .moveEsquerda {
	background-image: url(./../../imgs/moveLeft.png);
}
.Controles .moveDireita {
	background-image: url(./../../imgs/moveRight.png);
}
.Controles .moveEsquerda, .Controles .moveDireita {
	width: 19px;
	height: 23px;
}
.Controles .moveEsquerda:disabled, .Controles .moveDireita:disabled {
	background-position: -38px 0;
}