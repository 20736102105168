.LinhaDoTempo {
	counter-reset: layerCount;
	counter-increment: 4;
}
.Camada {
	background-image: url(./../../imgs/timeline-layer-background-2.png);
	display: inline-block;
	margin: 1px 0;
	padding: 2px;
	padding-left: 1px;
	white-space: nowrap;
	position: relative;

	width: 531px;
    height: 25px;
}
.Camada:before {
	counter-increment: layerCount;
    content: counter(layerCount);
    transform: translate(-50%);
    position: absolute;
    left: -6px;
    top: 8px;
    font-size: 7pt;
    color: white;
    text-shadow: -1px 0px 0px black, 0px -1px 0px black, -1px -1px 0px black, 1px 0px 0px black, 0px 1px 0px black, 1px 1px 0px black;
}
.Camada .modulo {
	margin-left: 1px;
}