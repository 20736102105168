.PrimeiraVez {
	position: absolute;
	top: 16px;
	right: -3px;
}
.PrimeiraVez > main {
	font-weight: bold;
	padding: 10px 7px 10px 6px !important;
}
.PrimeiraVez > main > span {
	margin: 7px 0;
}
.PrimeiraVez > main > button {
	text-decoration: underline;
	font-weight: bold;
}