.ListaDeReproducao {
	width: 207px;

}
.ListaDeReproducao h2 {
	color: #eee;
	text-align: center;
	margin-bottom: 7px;
}
.ListaDeReproducao .lista {
	width: 207px;
	height: 100px;
	margin-bottom: 7px;
	padding: 5px;
	background-image: url(./../../imgs/playlist-background.png);
}
.ListaDeReproducao .salvar {
	color: #eee;
	display: block;
	width: 181px;
	height: 23px;
	margin: 0 auto;
	border: 5px transparent solid;
	border-image: url(./../../imgs/button.png) 5 repeat;
	/*background-image: url(./../../imgs/button.png);*/
	background-color: #5e9195;
	background-clip: padding-box;
}