.InfoMusica {
	position: absolute;
	right: 16px;
	bottom: 17px;
}
.InfoMusica h2 {
	margin-bottom: 7px;
	text-align: center;
	color: #a7d5d9;
}
.InfoMusica .info {
	width: 207px;
	height: 94px;
	background-image: url(./../../imgs/song-info-background.png);
}
.InfoMusica .info span {
	font-weight: normal;
	display: block;
	text-align: center;
	line-height: 56px;
	color: #b5dbde;
}
.InfoMusica .gravar {
	color: #eee;
	display: block;
	margin: 4px auto;
	width: 132px;
	height: 23px;
	border: 5px transparent solid;
	border-image: url(./../../imgs/button.png) 5 repeat;
	background-color: #5e9195;
	background-clip: padding-box;
}