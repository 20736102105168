.Conectar {
	position: absolute;
	top: 126px;
	right: -3px;
}
.Conectar > main > form > button {
	text-decoration: underline;
	float: right;
	width: 70px;
	text-align: right;
	margin-top: 4px;
}
.Conectar > main > form > label > input:not([type="submit"]) {
	margin-top: 5px;
	margin-bottom: 5px;
}
.Conectar > main > form > input[type="submit"] {
	display: inherit;
}
.Conectar > main input {
	text-align: center;
}
.Conectar > footer > button {
	text-decoration: underline;
	font-weight: bold;
}