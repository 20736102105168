.Agulha[type=range] {
	-webkit-appearance: none;
	width: 531px;
	height: 10px;
	background: transparent;
	outline: none;
	z-index: 2;
	position: absolute;
	left: 12px;
	bottom: 3px;
	cursor: e-resize;
}
.Agulha[type=range]::-webkit-slider-thumb {
	-webkit-appearance: none;
	background-image: url(./../../imgs/agulha.png);
	height: 117px;
	width: 25px;
	margin-top: -107px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
	pointer-events: none;
}