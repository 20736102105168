.ListaSonsLinha {
	line-height: 6.2px;
	overflow: hidden;
	padding-bottom: 8px;
	
	/*font-size: 6pt;
	color: white;*/
}
.ListaSonsLinha img {
	float: left;
	margin-right: 4px;
	cursor: pointer;
}
.ListaSonsLinha span {
	color: white;
	font-size: 6pt;
	position: relative;
}
.ListaSonsLinha img:hover ~ span:after {
	content: "Usar";
	position: absolute;
	left: 0;
	bottom: -1em;
	color: lightgreen;
}