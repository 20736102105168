.CriadorMusica {
	width: 591px;
    height: 334px;
    padding: 5px 6px 5px 4px;
    background-image: url('./../../imgs/traxmachine-background.png');
}
.CriadorMusica .corpo * {
    font-weight: normal;
}
.CriadorMusica .corpo {
	width: 559px;
	margin: 11px auto;
}
.CriadorMusica .corpo > *:not(.LinhaDoTempo) {
	display: inline-block;
}