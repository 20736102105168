input[type=text],
input[type=password],
input[type=e-mail] {
	cursor: auto !important;
	min-width: 96px;
	height: 17px;
	padding: 1px 6px 0 6px;
	border: 1px solid transparent;
	border-image-source: url(./imgs/input_borda.png);
	border-image-repeat: repeat;
	border-image-width: auto;
	border-image-slice: 5 fill;
}
input[type=checkbox] {
	appearance: none;
	width: 12px;
	height: 12px;
	background-image: url(./imgs/input-checkbox_bg.png);
}
input[type=checkbox]:checked {
	background-position-x: -12px;
}
input[type=button] {
	font-weight: bold;
	/* background-image: url(./imgs/input-btn_fundo.png);
	background-clip: padding-box; */
	padding: 2px 9px 2px 7px;
	border: 2px solid transparent;
	border-image-source: url(./imgs/input-btn_borda.png);
	border-image-repeat: repeat;
	border-image-width: auto;
	border-image-slice: 4 fill;
}
input.grande {
	border-image-source: url(./imgs/input-grande_borda.png);
	border-image-slice: 12;
	width: 165px;
	height: 34px;
	padding: 4px;
	font-size: 18px;
}

input[type="submit"] {
	font-weight: bold;
	padding: 2px 7px;
	border: 6px solid transparent;
	border-image-source: url(./imgs/input-submit_borda.png);
	border-image-repeat: repeat;
	border-image-width: auto;
	border-image-slice: 6 fill;
}
input:disabled {
	background-color: rgba(0, 0, 0, .4);
	color: black;
}