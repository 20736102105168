@font-face {
	font-family: 'Volter';
	/*src: url(./fonts/Volter_Goldfish.ttf);*/
	src: url(./fonts/volter.ttf);
}
@font-face {
	font-family: 'Volter';
	src: url('./fonts/Volter-Bold_Goldfish.ttf');
	font-weight: bold;
}
.Traxometro, .Traxometro > * {
	width: 100vw;
	height: 100vh;
}
.Traxometro > * {
	position: absolute;
	overflow: auto;
}
/*.Traxometro > *:not(.tocando-agora) {
	margin: 0 auto;
	position: relative;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
}*/
.Traxometro * {
	outline: none;
	font-family: 'Volter', monospace;
	font-size: 9px;
	letter-spacing: .4pt;
	user-select: none;
}
.Traxometro .tocando-agora {
	position: absolute;
	right: 5px;
	top: 5px;
	opacity: 0;
	z-index: 9;
	width: 190px;
	height: 66px;
	background-image: url(./imgs/playing-now-background.png);
	padding: 11px 3px 5px 68px;
	transition: opacity 2s;
	pointer-events: none;
	color: #eee;
}
button {
	cursor: pointer;
}

.cabecalho {
	position: relative;
	text-align: center;
	border-radius: 10px 10px 0 0;
	height: 15px;
}
.cabecalho h1 {
	display: inline-block;
	color: white;
	font-size: 9px;
	padding: 0 6px;
    vertical-align: top;
    padding-top: 5px;
}
.cabecalho .fechar {
	position: absolute;
	top: 1px;
	right: 7px;
	width: 13px;
	height: 13px;
	border: 1px solid;
	background-position: center;
	background-repeat: no-repeat;
}
.cabecalho.escuro {
	background-image: url(./imgs/header-background-dark.png);
}
.cabecalho.escuro h1 {
	background-color: #587580;
}
.cabecalho.escuro .fechar {
	border-color: #587580;
	background-image: url(./imgs/button-close-dark.png);
	background-color: #587580;
}